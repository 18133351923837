// NextUI Imports
import { Avatar, Button, Card, CardBody, CardFooter } from "@nextui-org/react";

// Images
import AvatarImage from "../../assets/images/ProfilePicture.jpg";

export default function HeroSection() {
  return (
    <Card className="bg-content1 bg-opacity-10 backdrop-blur-sm p-6 md:p-8 max-w-full md:max-w-lg">
      <CardBody className="flex flex-col items-center space-y-4 text-center">
        <Avatar src={AvatarImage} className="w-32 h-32" />
        <h3 className="text-base">Hello, I'm Rifki! 👋</h3>
        <h2 className="text-4xl md:text-5xl text-transparent bg-gradient-to-r from-[#a6e3a1] via-[#cba6f7] to-[#f38ba8] bg-clip-text animate-gradient bg-300%">
          Empowering with Code, Delivering Excellence.
        </h2>
      </CardBody>
      <CardFooter className="flex flex-row items-center justify-center space-x-4">
        <Button color="secondary" as={"a"} href="/#contact">
          Get in Touch
        </Button>
        <Button
          color="success"
          as={"a"}
          href="/docs/Rifki_Salim_Resume.pdf"
          download={true}
        >
          Download Resume
        </Button>
      </CardFooter>
    </Card>
  );
}
