// NextUI Imports
import { Button, Divider } from "@nextui-org/react";

export default function ContactSection() {
  return (
    <div className="flex-1 flex flex-col justify-center items-center w-full">
      <div
        id="contact"
        className="flex flex-col items-center justify-center text-center w-full p-8"
      >
        <Divider className="my-8 w-4/5" />
        <h2 className="text-5xl lg:text-6xl font-bold mb-4 bg-gradient-to-r from-default-50 to-default-500 bg-clip-text text-transparent">
          Get in Touch
        </h2>
        <div className="flex flex-col space-y-8 p-2 justify-center text-center items-center w-full">
          <div className="flex flex-row w-full justify-center items-center space-x-4">
            <Button
              color="secondary"
              as="a"
              href="https://calendly.com/rifkisalim/consultation-free"
            >
              Book a Meeting
            </Button>
            <Button color="success" as="a" href="mailto:contact@rifkisalim.com">
              Email Me
            </Button>
          </div>
        </div>
        <Divider className="my-8 w-3/5" />
      </div>
    </div>
  );
}
