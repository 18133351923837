// NextUI Imports
import { Card, CardBody, User, CardHeader } from "@nextui-org/react";

// Custom Components
import Rating from "../Misc/Rating";

interface ITestimonialCardProps {
  name: string;
  avatarUrl: string;
  quote: string;
  rating: 1 | 2 | 3 | 4 | 5;
}

export default function TestimonialCard(props: ITestimonialCardProps) {
  return (
    <Card className="w-96 h-[500px] p-6 bg-opacity-50 backdrop-blur-lg">
      <CardHeader className="p-4 border-b border-b-divider">
        <User
          name={props.name}
          description={<Rating rating={props.rating} />}
          classNames={{
            name: "text-lg",
          }}
          avatarProps={{
            src: props.avatarUrl,
            size: "lg",
          }}
        />
      </CardHeader>
      <CardBody className="flex flex-col text-center justify-center items-center space-y-4">
        <svg
          className="w-28 h-28 mx-auto mb-2 text-content3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 18 14"
        >
          <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
        </svg>
        <blockquote className="w-full max-h-max overflow-y-auto p-2">
          <p className="text-lg italic font-medium">"{props.quote}"</p>
        </blockquote>
      </CardBody>
    </Card>
  );
}
