export default function AboutSection() {
  return (
    <div className="flex flex-col items-center justify-center text-center w-full space-y-8">
      <div className="w-full bg-content1 bg-opacity-30 backdrop-blur-md border-divider border-y-1 flex p-8 flex-col items-center justify-center">
        <h2 className="text-3xl lg:text-5xl font-bold w-full lg:w-2/3 text-center leading-snug bg-gradient-to-r from-default-50 to-default-500 bg-clip-text text-transparent lg:p-2">
          Coding as Artistry: Crafting Innovation Stroke by Stroke
        </h2>
      </div>
      <div className="flex flex-col p-8 text-xl lg:text-3xl border-divider border-y justify-center text-center items-center w-full lg:w-4/5 bg-content1 bg-opacity-60 backdrop-blur-lg">
        <h2 className="leading-relaxed">
          Where every keystroke paints a{" "}
          <span className="highlight-text-anim hover-underline-animation">
            canvas of creativity,
          </span>{" "}
          and algorithms dance to the{" "}
          <span className="highlight-text-anim hover-underline-animation">
            rhythm of inspiration,
          </span>{" "}
          yielding digital masterpieces that{" "}
          <span className="highlight-text-anim hover-underline-animation whitespace-nowrap">
            captivate, motivate, and redefine.
          </span>
        </h2>
      </div>
    </div>
  );
}
