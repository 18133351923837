const StarRating = ({ rating }: { rating: number }) => {
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    stars.push(
      <span key={i} className="text-transparent text-xl">
        ★
      </span>
    );
  }

  return (
    <>
      <div className="bg-gradient-to-r from-[#f38ba8] via-[#cba6f7] to-[#f9e2af] bg-clip-text animate-gradient-fast bg-300%">
        {stars}
      </div>
    </>
  );
};

export default StarRating;
