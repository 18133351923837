import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { NextUIProvider } from "@nextui-org/react";
import Landing from "./components/Landing";

function App() {
  return (
    <NextUIProvider>
      <div
        style={{
          backgroundImage:
            "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%2345475a' fill-opacity='0.3'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
        }}
        className="bg-fixed flex flex-col items-center justify-center dark text-foreground bg-background w-full min-h-screen"
      >
        <Landing />
      </div>
    </NextUIProvider>
  );
}

export default App;
