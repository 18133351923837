// React Imports
import { useCallback } from "react";

// Physics/Particles
import type { Container, Engine } from "tsparticles-engine";
import { loadSlim } from "tsparticles-slim";
import Particles from "react-tsparticles";

// Custom Components and Sections
import Header from "./Header";
import HeroSection from "./Sections/HeroSection";
import ServicesSection from "./Sections/ServicesSection";
import TestimonialsSection from "./Sections/TestimonialsSection";
import SmoothScroll from "./SmoothScrollWithNyan";
import ContactSection from "./Sections/ContactSection";
import AboutSection from "./Sections/AboutSection";
import ProjectsSection from "./Sections/ProjectsSection";

// SEO
import Helmet from "react-helmet";

// Assets
import PROFILE_IMG from "../assets/images/ProfilePicture.jpg";

export default function Landing() {
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {},
    []
  );

  return (
    <div className="inset-0 z-10 w-full overflow-y-auto">
      <Helmet>
        <title>Rifki Salim</title>
        <meta
          name="description"
          content="Freelance Software Engineer offering web and mobile app development, IT consulting, mentoring, and Linux SysAdmin services worldwide."
        />
        <meta
          name="keywords"
          content="Software Engineer, Freelance Developer, Web Development, Mobile App Development, IT Consulting, Linux SysAdmin, IT Mentor, IT Tutor, React, Node.js, Remix, Tailwind, Python, IoT"
        />
        <link rel="canonical" href="https://rifkisalim.com" />

        {/* Social Media Links */}
        <meta property="og:type" content="profile" />
        <meta property="og:title" content="Rifki Salim - Software Engineer" />
        <meta
          property="og:description"
          content="Freelance Software Engineer offering web and mobile app development, IT consulting, mentoring, and Linux SysAdmin services worldwide."
        />
        <meta property="og:image" content={PROFILE_IMG} />
        <meta property="profile:first_name" content="Rifki" />
        <meta property="profile:last_name" content="Salim" />
        <meta property="profile:gender" content="male" />
        <meta property="profile:username" content="rifkisalim.dev" />
        <meta property="profile:username" content="rifkisalim" />
        <meta property="profile:username" content="RifkiSalim" />

        {/* Contact Information */}
        <meta name="email" content="contact@rifkisalim.com" />

        {/* Call to Action */}
        <meta name="cta" content="Get in Touch" />
        <meta name="cta" content="Download My Resume" />
      </Helmet>

      <Header />
      <SmoothScroll>
        <div className="relative flex flex-col w-full min-h-screen items-center justify-center">
          <Particles
            className="absolute w-full h-full inset-0"
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            url="/lib/particles/particles.json"
          />
          <HeroSection />
        </div>
        <div className="flex flex-col justify-center items-center min-h-screen w-full">
          <AboutSection />
        </div>
        <div className="flex flex-col justify-center items-center min-h-screen w-full">
          <ServicesSection />
        </div>
        <div className="flex flex-col justify-center items-center min-h-screen w-full">
          <TestimonialsSection />
        </div>
        <div className="flex flex-col justify-center items-center min-h-screen w-full">
          <ProjectsSection />
        </div>
        <div
          className="flex flex-col justify-center items-center min-h-screen w-full"
          id="contact"
        >
          <ContactSection />
          <div className="flex-0 border-t border-divider flex flex-row justify-between items-center p-2 bg-content2 backdrop-blur-sm bg-opacity-10 w-full">
            <h4 className="text-sm font-extralight w-full text-center">
              © {new Date().getFullYear()} Rifki Salim - All Rights Reserved.
            </h4>
          </div>
        </div>
      </SmoothScroll>
    </div>
  );
}
