import { MotionValue, motion, useSpring, useTransform } from "framer-motion";
import NyanCatGif from "../assets/images/nyan-cat.gif";

interface INyanCatProps {
  pageHeight: number;
  scrollY: MotionValue<number>;
}

export default function NyanCat(props: INyanCatProps) {
  const physics = { damping: 10, mass: 0.15, stiffness: 30 };
  const catTransform = useTransform(
    props.scrollY, // Use the prop directly
    [0, props.pageHeight],
    [-500, window.innerWidth + 1000]
  );
  const catSpring = useSpring(catTransform, physics);

  return (
    <motion.div
      className="nyan-cat -z-10"
      style={{
        position: "fixed",
        top: `${window.innerHeight / 2}px`,
        x: catSpring,
        opacity: 0.6,
      }}
      initial={{ x: -500 }}
    >
      <img
        src={NyanCatGif}
        alt="Nyan Cat"
        className="w-64 h-auto object-contain"
      />
    </motion.div>
  );
}
