import TestimonialCard from "../Cards/TestimonialCard";
import { useState } from "react";
import { AnimatePresence, Variants, motion, wrap } from "framer-motion";
import Michael_K_IMG from "../../assets/testimonial-images/michael_k.png";

interface ITestimonial {
  avatarUrl: string;
  name: string;
  quote: string;
  rating: 1 | 2 | 3 | 4 | 5;
}
const testimonials: ITestimonial[] = [
  {
    avatarUrl: Michael_K_IMG,
    name: "Michael K.",
    rating: 5,
    quote:
      "It has been a pleasure working with Rifki. His greatest strengths are his ability to problem solve and his communication/availability. I threw him many curve balls and with limited direction from me he was able to take initiative and build features for my website that were elegant and clean and then adapt them to what I needed. He is very patient and willing to explain things which is so helpful throughout the process!",
  },
];

const sliderVariants: Variants = {
  incoming: (direction) => ({
    x: direction > 0 ? "100%" : "-100%",
    scale: 1.2,
  }),
  active: { x: 0, scale: 1 },
  exit: (direction) => ({
    x: direction > 0 ? "-100%" : "100%",
    scale: 1,
  }),
};

const sliderTransition = {
  duration: 1,
  ease: [0.56, 0.03, 0.12, 1.04],
};

export default function TestimonialsSection() {
  const [[tCount, direction], setImageCount] = useState([0, 0]);

  const activeTestimonialIndex = wrap(0, testimonials.length, tCount);

  const skipToImage = (tId: number) => {
    let changeDirection = 1;
    if (tId > activeTestimonialIndex) {
      changeDirection = 1;
    } else if (tId < activeTestimonialIndex) {
      changeDirection = -1;
    }
    setImageCount([tId, changeDirection]);
  };
  setTimeout(() => {
    skipToImage(activeTestimonialIndex + 1);
  }, 5000);
  return (
    <div className="flex flex-col items-center justify-center text-center w-full space-y-8">
      <div className="w-full bg-content1 bg-opacity-30 backdrop-blur-md border-divider border-y-1 flex p-8 flex-col items-center justify-center">
        <h2 className="text-3xl lg:text-5xl font-bold w-full lg:w-2/3 text-center leading-snug bg-gradient-to-r from-default-50 to-default-500 bg-clip-text text-transparent lg:p-2">
          Whispers of Success: Client Commendations
        </h2>
      </div>
      <div className="flex flex-col space-y-8 p-2 justify-center text-center items-center w-full">
        <div className="flex flex-col items-center">
          <div className="relative w-96 h-[500px] overflow-hidden">
            <AnimatePresence initial={false} custom={direction}>
              <motion.div
                key={tCount}
                custom={direction}
                variants={sliderVariants}
                initial="incoming"
                animate="active"
                exit="exit"
                transition={sliderTransition}
                className="absolute h-full w-full"
              >
                <TestimonialCard
                  avatarUrl={testimonials[activeTestimonialIndex].avatarUrl}
                  name={testimonials[activeTestimonialIndex].name}
                  quote={testimonials[activeTestimonialIndex].quote}
                  rating={testimonials[activeTestimonialIndex].rating}
                />
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
}
