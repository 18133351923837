// Images
import NFSBattles_Mockup from "../../assets/project-mockups/nfsbattles.jpeg";

// Project Card
import ProjectCard, { IProjectCardProps } from "../Cards/ProjectCard";

export default function ProjectsSection() {
  const items: IProjectCardProps[] = [
    {
      imageUrl: NFSBattles_Mockup,
      name: "NFS Battles",
      cta: "https://nfsbattles.com/",
      tags: ["Full Stack", "React/Remix"],
    },
  ];

  function getGridClass(childCount: number) {
    if (childCount === 1) {
      return "justify-center";
    } else if (childCount <= 4) {
      return "grid-cols-2 justify-center";
    } else {
      return "grid-cols-4";
    }
  }

  return (
    <div className="flex flex-col items-center justify-center text-center w-full space-y-8">
      <div className="w-full bg-content1 bg-opacity-30 backdrop-blur-md border-divider border-y-1 flex p-8 flex-col items-center justify-center">
        <h2 className="text-3xl lg:text-5xl font-bold w-full lg:w-2/3 text-center leading-snug bg-gradient-to-r from-default-50 to-default-500 bg-clip-text text-transparent lg:p-2">
          Exploring Endeavors: Showcasing Digital Voyages
        </h2>
      </div>
      <div className={`grid ${getGridClass(items.length)} gap-4 mx-4`}>
        {items.map((item, index) => {
          return <ProjectCard key={index} {...item} />;
        })}
      </div>
    </div>
  );
}
