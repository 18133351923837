// NextUI Imports
import { Card, CardBody, CardHeader } from "@nextui-org/react";

// Types
import { IconType } from "react-icons";

interface IServiceCardProps {
  icon: IconType;
  title: string;
  description: string;
}

export default function ServiceCard(props: IServiceCardProps) {
  return (
    <Card className="max-w-xs h-72 p-4 bg-opacity-50 backdrop-blur-lg">
      <CardHeader className="flex flex-col space-y-4 items-center justify-center">
        <props.icon size={50} />
        <h2 className="text-base font-bold">{props.title}</h2>
      </CardHeader>
      <CardBody className="flex flex-col text-center justify-center items-center space-y-4">
        <div className="flex flex-col space-y-2">
          <h3 className="text-sm font-extralight">{props.description}</h3>
        </div>
      </CardBody>
    </Card>
  );
}
