// NextUI Imports
import { Divider } from "@nextui-org/react";

//Icons
import { FaGithub, FaInstagram, FaLinkedinIn } from "react-icons/fa6";

export default function Header() {
  return (
    <div className="flex flex-col top-0 left-0 fixed w-full z-10">
      <header className="px-4 py-2 flex flex-row justify-between bg-opacity-30 bg-content1 backdrop-blur-sm w-full">
        <h1 className="text-xl font-extrabold ">Rifki Salim</h1>
        <div className="flex flex-row items-center space-x-4">
          <a href="https://linkedin.com/in/rifkisalim">
            <FaLinkedinIn size={20} />
          </a>
          <Divider orientation="vertical"></Divider>
          <a href="https://github.com/RifkiSalim">
            <FaGithub size={20} />
          </a>
          <Divider orientation="vertical"></Divider>

          <a href="https://instagram.com/rifkisalim.dev">
            <FaInstagram size={20} />
          </a>
        </div>
      </header>
      <Divider orientation="horizontal" />
    </div>
  );
}
