// Card
import ServiceCard from "../Cards/ServiceCard";

// Icons
import {
  FaChalkboardUser,
  FaDesktop,
  FaLightbulb,
  FaServer,
} from "react-icons/fa6";

export default function ServicesSection() {
  return (
    <div className="flex flex-col items-center justify-center text-center w-full space-y-8">
      <div className="w-full bg-content1 bg-opacity-30 backdrop-blur-md border-divider border-y-1 flex p-8 flex-col space-y-2 items-center justify-center">
        <h2 className="text-3xl lg:text-5xl font-bold w-full lg:w-2/3 text-center leading-snug bg-gradient-to-r from-default-50 to-default-500 bg-clip-text text-transparent lg:p-2">
          Forging Digital Synergy for Outstanding Outcomes
        </h2>
      </div>
      <div className="flex flex-col space-y-8 p-2 justify-center text-center items-center w-full">
        <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row w-full justify-center items-center lg:space-x-4">
          <ServiceCard
            icon={FaDesktop}
            title="Web and Mobile App Development"
            description="Expertise in crafting dynamic and user-friendly web and mobile applications tailored to your needs."
          />
          <ServiceCard
            icon={FaLightbulb}
            title="IT Consulting for Startups"
            description="Strategic IT guidance for startups and small scale businesses, helping you leverage technology to achieve your business goals."
          />
          <ServiceCard
            icon={FaServer}
            title="Linux System Administration"
            description="Management and optimization of Linux-based systems, ensuring seamless operations and security."
          />
          <ServiceCard
            icon={FaChalkboardUser}
            title="IT Tutoring & Mentoring"
            description="Expert guidance in IT fundamentals and advanced topics, empowering you to excel in technology and achieve your goals."
          />
        </div>
      </div>
    </div>
  );
}
