import React, { useRef, useState, ReactNode, useEffect } from "react";
import { useMotionValue } from "framer-motion";
import { ScrollerMotion, ScrollerMotionRef } from "scroller-motion";
import NyanCat from "./NyanCat";

interface SmoothScrollProps {
  children: ReactNode;
}

const SmoothScroll: React.FC<SmoothScrollProps> = ({ children }) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const scrollerMotion = useRef<ScrollerMotionRef>();
  const scrollY = useMotionValue(0);
  const [pageHeight, setPageHeight] = useState<number>(0);

  // Function to calculate the page height based on the content
  const calculatePageHeight = () => {
    if (scrollRef.current) {
      const contentHeight = scrollRef.current.scrollHeight;
      console.log(contentHeight);
      setPageHeight(contentHeight);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      calculatePageHeight();
    }, 100); // Adjust the delay as needed
    // Recalculate the page height when the content changes
    window.addEventListener("resize", calculatePageHeight);

    return () => {
      window.removeEventListener("resize", calculatePageHeight);
    };
  }, []);

  useEffect(() => {
    const unsubscribe =
      scrollerMotion.current &&
      scrollerMotion.current.scrollY.on("change", (v) => scrollY.set(v));

    return () => unsubscribe && unsubscribe();
  }, [scrollY]);

  useEffect(() => {
    const listenHashChange = (e: HashChangeEvent) => {
      const { newURL } = e;
      const match = newURL.match(/#(.*)$/);
      const id = match && match[1];
      if (id) {
        const el = document.getElementById(id);
        if (el) {
          window.scrollTo(0, el.offsetTop);
        }
      } else {
        window.scrollTo(0, 0);
      }
    };

    window.addEventListener("hashchange", listenHashChange);

    return () => window.removeEventListener("hashchange", listenHashChange);
  }, []);

  return (
    <>
      <div ref={scrollRef}>
        <ScrollerMotion ref={scrollerMotion}>{children}</ScrollerMotion>
      </div>
      <NyanCat pageHeight={pageHeight} scrollY={scrollY} />
    </>
  );
};

export default SmoothScroll;
