// NextUI imports
import { Card, CardFooter, Chip, Button, Image } from "@nextui-org/react";

// Icons
import { BiLinkExternal } from "react-icons/bi";

export interface IProjectCardProps {
  imageUrl: string;
  name: string;
  tags: [string, string];
  cta: string;
}

export default function ProjectCard(props: IProjectCardProps) {
  return (
    <div className="flex flex-row items-center justify-center w-full">
      <Card className="max-w-xl h-[350px] col-span-12 sm:col-span-7">
        <Image
          removeWrapper
          className="z-0 w-full h-full object-cover"
          src={props.imageUrl}
          alt="Project Mockup"
        />
        <CardFooter className="absolute bg-background flex flex-row justify-between bg-opacity-70 backdrop-blur-lg bottom-0 z-10 border-t-1 border-divider">
          <div className="flex flex-col items-start">
            <p className="text-base">{props.name}</p>
            <div className="flex flex-row justify-center space-x-2">
              <Chip
                size="sm"
                className="bg-gradient-to-r from-[#eba0ac] to-[#b4befe] text-content1"
              >
                {props.tags[0]}
              </Chip>
              <Chip
                size="sm"
                className="bg-gradient-to-r from-[#eba0ac] to-[#b4befe] text-content1"
              >
                {props.tags[1]}
              </Chip>
            </div>
          </div>
          <Button
            size="sm"
            color="secondary"
            as={"a"}
            href={props.cta}
            target="_blank"
          >
            <BiLinkExternal size={20} />
            View
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
